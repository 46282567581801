<template>
  <div class="solution">
     <!-- 导航栏 -->
    <NavBarHome v-if="navBarStyle === '1'" textColor="#000000" :iconUrl="require('@/assets/img/home/logo-2.png')"/>
    <NavBar v-else />
		<router-view class="router-view"></router-view>
    <Footer></Footer>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
import NavBarHome from "@/components/NavBarHome";
import Footer from "@/components/Footer";
import {scrollMixin} from '@/mixins/scroll.js'
export default {
  mixins: [scrollMixin],
  components: {
    NavBar,
    NavBarHome,
    Footer
  },
};
</script>
<style  lang="scss">

</style>